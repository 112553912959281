import {
    OrdersActionTypes,
	OrdersDataState,
	SET_ORDER_LIST
} from "./@types";

let initialState: OrdersDataState = {
	list: [],

};

const ordersReducer = (state = initialState, action: OrdersActionTypes) => {
	switch (action.type) {
		case SET_ORDER_LIST:
			return {
				...state,
				list: action.list
			}


		default:
			return state;
	}
};

export default ordersReducer;
