import * as React from "react";
import "./Overlay.css";

interface IProps {
    opacity?:number,
    zIndex?:number,
  onClick?: Function
}

const Overlay: React.FC<IProps> = ( {onClick,zIndex,opacity }) => {
  return (
    <>
       <div className="overlay"
            onClick={() =>  onClick ? onClick() : null}
            style={{ zIndex: zIndex ? zIndex : 30 , opacity: opacity ?opacity : 0.5}}>
       </div>
    </>
  )
};

export default Overlay;
