import React, { Component } from "react";

import "./OrderInfo.css"
import {EnumPayType, OrderStruct} from "../../redux/reducers/orders/@types";
import {Overlay} from "../Elements/elements";
import moment from "moment";
import {
    checkAndTakeOrder,
    checkOrder,
    getCargoSize,
    getCategory,
    takeOrder
} from "../../redux/reducers/system/reducers";
import {useDispatch} from "react-redux";
import {setSystemMessage, toggleOrderDisagree} from "../../redux/reducers/system/actions";
import {MessageType, SystemState} from "../../redux/reducers/system/@types";
import {imagePath} from "../../deployment";
import Checkbox from "../Elements/Checkbox/Checkbox";




interface IProps {
    system: SystemState,
    body_option: string,
    category_id: string,
    order: OrderStruct ,
    onClose?: Function
}

const OrderInfo: React.FC<IProps> = ({order,category_id , onClose, body_option, system}) => {
    const dispatch = useDispatch();

    let date = order.date.replaceAll("Т", "T");
    let time =  moment(date).format("HH:mm DD.MM.YY");

    const  thisTakeOrder = () =>  {
        checkAndTakeOrder(order.id, system, dispatch);
    };

    let  closeWindow = () => {
        if (onClose) onClose();
    };

    const checkRoute = () => {
        if (!order.route)
            dispatch(setSystemMessage("Отсутсвует привязка маршрута к данному заказу", MessageType.Info));
        else
            window.open( order.route,   '_blank');
    };

    const setDisagree = (order_id : string) => {
        dispatch(toggleOrderDisagree(order_id))
    };

    console.log(category_id);
    let weight = getCategory(category_id).maxWeight;

    const cargo_size = getCargoSize(order);
    return (
        <>
            <Overlay onClick={closeWindow} zIndex={10} />
            <div className="orderInfo" style={{'top': window.pageYOffset + 'px'}}>
                <div className="page_back_link" onClick={() => onClose ? onClose() : null}>
                    <img src={imagePath+"/image/close.svg"} alt="закрыть"  className="close" onClick={closeWindow}/>
                </div>
                <div >
                    <div className='orderNumberTitle'>Подача: {time} </div>
                </div>
                <div className='orderNumber middleLine'>

                        <table>
                            <tbody>
                                <tr><td>Стоимость</td><td>от  {Math.round(order.min_cost)} ₽  до {Math.round(order.cost)} ₽ </td></tr>
                                <tr><td>Средняя стоимость часа </td><td>~ {Math.round((order.min_cost / order.hours_from + order.cost / order.hours_to) / 2)} ₽</td></tr>
                                <tr><td>Время на маршруте  </td><td>~ {((order.hours_from + order.hours_to )/ 2).toFixed(1)} ч.</td></tr>
                                <tr><td>Грузоподъемность до</td><td>{weight}кг. </td></tr>
                                <tr><td>Габариты груза (ДШВ) </td><td>{cargo_size.length + 'м ' + cargo_size.width + 'м ' + cargo_size.height + 'м'}</td></tr>
                                <tr><td>Тип кузова </td><td>{body_option}</td></tr>
                                {order.pay_dates && order.pay_dates.length &&
                                order.pay_dates.map((x,index) => <tr key={'pay_dates_' + index}><td className='pay_dates' colSpan={2}>{x.description}</td></tr>)
                                }
                                {(!order.pay_dates || !order.pay_dates.length) &&
                                <>
                                    <tr><td>Оплата  </td><td>{order.pay_type == EnumPayType.CASH ? 'наличный расчет' : 'безналичный расчет'}</td></tr>
                                    <tr><td>Дата оплаты </td><td>{ moment(order.pay_date).format("DD.MM.YY")}</td></tr>
                                </>
                                }
                            </tbody>
                        </table>

                </div>
                <div className="scrollArea">
                    <div className='orderNumber middleLine'>
                        <div dangerouslySetInnerHTML={ {__html: order.description.replaceAll("\n", "<br /><br />" )}}></div>
                    </div>
                </div>
                <div className='orderNumber middleLine buttonMedia buttonCenter'>
                    <div className="buttonLink " onClick={checkRoute} >Маршрут на карте</div>
                    <div className={"buttonLink greenButton " + (order.confirmed || order.disagree ? "disabledButton" : "") }
                         onClick={()=> { if (!order.confirmed && !order.disagree) thisTakeOrder() }}>
                        {order.confirmed ? "заказ принят" : "принять заказ"}
                    </div>
                </div>
                <div>
                    <div className="owner_select_agree" onClick={(e) => {
                        // e.preventDefault();
                        setDisagree(order.id);
                        // e.stopPropagation();
                    }}>

                        <div className="owner_select_agree_check">
                            <Checkbox id="owner_select_agree" checked={!order.disagree}
                                      className={order.disagree ? 'error' : ''}
                            />
                        </div>
                        <div className="owner_select_agree_text">
                            Принимая заказ я соглашаюсь с&nbsp;
                            <a onClick={(e) => e.stopPropagation()}
                               target="_blank" href="./terms-of-use.html"
                               className="owner_select_agree_link">условиями публичной оферты</a>&nbsp;и&nbsp;
                            <a onClick={(e) => e.stopPropagation()}
                               target="_blank" href="./privacy-policy.html"
                               className="owner_select_agree_link">политикой конфиденциальности</a>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
};

export default OrderInfo;
