import * as React from "react";
import {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import {Provider, useDispatch, useSelector} from "react-redux";

import store, {RootState} from "../../redux/store";

import {driverAPI, orderAPI} from "../../api/api";

import {MainScreen,} from "../components";
import "./App.css";

import {
    setBodyOptions,
    setDriverData,
    setDriverID, setListCategories,
    setSystemMessage,
    showUpdateDataWindow, toggleLoadingWindow
} from "../../redux/reducers/system/actions";
import {BodyOptionsType, MessageType, SystemState} from "../../redux/reducers/system/@types";
import {ResponseData} from "../../api/@types";
import Message from "../Message/Message";

import {checkAndTakeOrder, loadOrdersFromServer} from "../../redux/reducers/system/reducers";
import LoadingWindow from "../LoadingWindow/LoadingWindow";
import {updatePageTimeInSeconds} from "../../deployment";
import {getCookie} from "../../redux/cookies";

let intervalUpdateOrders:any = undefined;

const App: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => ({
        system: state.systemReducer,
    }));
    const system: SystemState = state.system;
    const data : ResponseData | null = system.data ? system.data : null;

    useEffect(() => {

        let url = new URL(window.location.href);
        let id : string = url.searchParams.get('id') != null ? url.searchParams.get('id') as string : '';

        if (id === '') {
            id = getCookie('driver_id');
        }
        if (true) {
            dispatch(toggleLoadingWindow(true));

            loadOrdersFromServer( id , dispatch).then(()=>{
                dispatch(toggleLoadingWindow(false));
                dispatch(setDriverID(id));
            }).catch(()=> dispatch(toggleLoadingWindow(false)));

            orderAPI.getCategoriesList().then((res)  => {
                if (res.status !== 200 ) dispatch(setSystemMessage("Ошибка получения перечня типов кузова !", MessageType.Error));
                dispatch(setListCategories(res.data));
            }).catch((err) => {
                dispatch(setSystemMessage("Ошибка: в процессе получения данных с сервера перечня типов кузова!", MessageType.Error));
            });

            orderAPI.getBodyOptionsList().then((res)  => {
                if (res.status !== 200 ) dispatch(setSystemMessage("Ошибка получения перечня типов кузова !", MessageType.Error));
                dispatch(setBodyOptions(res.data));
            }).catch((err) => {
                dispatch(setSystemMessage("Ошибка: в процессе получения данных с сервера перечня типов кузова!", MessageType.Error));
            });
            // orderAPI.getCategoriesFull().then((res)  => {
            //     if (res.status !== 200 ) dispatch(setSystemMessage("Ошибка получения перечня типов кузова !", MessageType.Error));
            //
            //     dispatch(setListCategories(res.data));
            //     let categories_full :any[] = res.data;
            //     let body_options_m : BodyOptionsType[] = [];
            //     categories_full.forEach( category=> {
            //         body_options_m = body_options_m.concat([...category.body_options.filter( (x:BodyOptionsType) => !body_options_m.find(z=> z.id === x.id) )])
            //     });
            //     dispatch(setBodyOptions(body_options_m));
            //
            // }).catch((err) => {
            //     dispatch(setSystemMessage("Ошибка: в процессе получения данных с сервера перечня типов кузова!", MessageType.Error));
            // });
        } else
            dispatch(setSystemMessage("Ошибка : невозможно идентифицировать пользователя !", MessageType.Error));
        //dispatch(showUpdateDataWindow());
    }, []);

    useEffect(()=>{
        if (intervalUpdateOrders)  clearInterval(intervalUpdateOrders);
        intervalUpdateOrders = setInterval(() => {
                dispatch(toggleLoadingWindow(true));
                loadOrdersFromServer( system.driver_id , dispatch).then(()=>{
                    dispatch(toggleLoadingWindow(false));
                }).catch(()=> dispatch(toggleLoadingWindow(false)));
            }, updatePageTimeInSeconds * 1000);

        return () => {
            clearInterval(intervalUpdateOrders);
        }

    }, [system.driver_id])

    return (
        <>
            {system.is_loading && <LoadingWindow />}
            {system.message.type != MessageType.None && !data && <Message text={system.message.text} type={system.message.type} /> }
            {data && <MainScreen />}
        </>
    )
}

const Container: React.FunctionComponent = (props) => {
    return (
        <Provider store={store}>
            <BrowserRouter  basename="/autosearch" >
                <App />
            </BrowserRouter>
        </Provider>
    )
};

export default Container;
