import * as React from "react";
import "./OrderCard.css";
import {EnumPayType, OrderStruct} from "../../../redux/reducers/orders/@types";
import moment from "moment";
import {useDispatch} from "react-redux";
import {setSystemMessage, toggleOrderDisagree} from "../../../redux/reducers/system/actions";
import {MessageType, SystemState} from "../../../redux/reducers/system/@types";

import {checkAndTakeOrder, getCargoSize} from "../../../redux/reducers/system/reducers";
import Checkbox from "../Checkbox/Checkbox";

interface IProps {
    system: SystemState,

    order: OrderStruct,
    onClick?: Function
}

const OrderCard: React.FC<IProps> = ({system, order, onClick}) => {
    const dispatch = useDispatch();

    let date = order.date.replaceAll("Т", "T");
    let time = moment(date).format("HH:mm DD.MM.YY");

    const checkRoute = () => {
        if (!order.route)
            dispatch(setSystemMessage("Отсутсвует привязка маршрута к данному заказу", MessageType.Info))
    };

    const thisTakeOrder = () =>{
        checkAndTakeOrder(order.id, system, dispatch);
    };


    const getCargoType = () => {
        let cargo_type = "";
        if (order.cargo.packages.length) cargo_type = 'упаковки';
        if (order.cargo.pallets.length) cargo_type = cargo_type ? cargo_type + ' и паллеты' : 'паллеты';
        // if (order.cargo.places.length) cargo_type = cargo_type ? cargo_type + ' и по габаритам' : 'по габаритам';
        return cargo_type;
    };
    const setDisagree = (order_id : string) => {
        dispatch(toggleOrderDisagree(order_id))
    };

    const cargo_size = getCargoSize(order);

    return (
        <>
            <div className='orderCard' key={'order_card_' + order.id}>
                <div className='orderNumber topLine'>
                    <div className='orderNumberTitle textLeft'>Подача: {time} </div>
                    <div className='orderNumberTitle textLeft textColorDarkGray'>{order.adress.trim()}</div>
                </div>
                <div className='orderNumber  textInfo padding-unset'>
                    {order.pay_dates && order.pay_dates.length &&
                        order.pay_dates.map((x,index) => <div  key={'pay_dates_oc_' + index}> {x.description}</div>)
                    }
                    {(!order.pay_dates || !order.pay_dates.length) &&
                        <div>
                            Оплата:<strong>{order.pay_type == EnumPayType.CASH ? 'наличный расчет' : 'безналичный расчет'} {moment(order.pay_date).format("DD.MM.YY")}</strong>
                        </div>
                    }

                    <div>
                        <a href={order && order.route ? order.route : '#'} target="_blank" onClick={checkRoute} className="linkRoute">
                            Посмотреть маршрут
                        </a>
                   </div>

                </div>
                <div className='orderNumber  textInfo'>
                    <div>Стоимость от <span>{Math.round(order.min_cost)} ₽</span> до <span>{Math.round(order.cost)} ₽</span></div>
                    <div>Время на маршруте <span
                        className="blue">~ {((order.hours_from + order.hours_to) / 2).toFixed(1)} ч.</span></div>
                    <div>Стоимость
                        часа <span>~ {Math.round((order.min_cost / order.hours_from + order.cost / order.hours_to) / 2)} ₽</span>
                    </div>
                </div>
                <div className='orderNumber middleLine textInfo'>
                    <div>Груз: {(order.cargo.name ? order.cargo.name + (getCargoType().length ? ', ': ''): '') + getCargoType()}</div>
                    <div>
                        ДШВ:&nbsp;<span>{cargo_size.length + 'м ' + cargo_size.width + 'м ' + cargo_size.height + 'м'}</span>

                    </div>
                    <div>
                        Вес:&nbsp;<span>{cargo_size.weight + ' кг'}</span>
                    </div>
                </div>
                <div className='orderNumber middleLine buttonMedia'>
                    <div className="buttonLink " onClick={() => onClick ? onClick(order) : null}>детали заказа</div>
                    <div className={"buttonLink greenButton " + ((order.confirmed || order.disagree) ? "disabledButton" : "") }
                         onClick={()=> { if (!order.confirmed && !order.disagree) thisTakeOrder() }}>
                        {order.confirmed ? "заказ принят" : "принять заказ"}
                    </div>
                </div>
                <div>
                    <div className="owner_select_agree" onClick={(e) => {
                        e.preventDefault();
                        setDisagree(order.id);
                    }}>

                            <div className="owner_select_agree_check">
                                <Checkbox id="owner_select_agree" checked={!order.disagree}
                                          className={order.disagree ? 'error' : ''}
                                />
                            </div>
                            <div className="owner_select_agree_text">
                                Принимая заказ я соглашаюсь с&nbsp;
                                <a onClick={(e) => e.stopPropagation()}
                                   target="_blank" href="./terms-of-use.html"
                                   className="owner_select_agree_link">условиями публичной оферты</a>&nbsp;и&nbsp;
                                <a onClick={(e) => e.stopPropagation()}
                                   target="_blank" href="./privacy-policy.html"
                                   className="owner_select_agree_link">политикой конфиденциальности</a>
                            </div>
                    </div>

                </div>
            </div>
        </>
    )
};

export default OrderCard;
