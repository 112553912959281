import { combineReducers, createStore } from "redux";
import {

    systemReducer,
    categoriesReducer, ordersReducer
} from "./reducers";

const reducers = combineReducers({
    categoriesReducer,
    ordersReducer,
    systemReducer
});

const store = createStore(reducers);

export default store;

export type RootState = ReturnType<typeof reducers>;
