import React from "react";

import "./LoadingWindow.css"

import {Overlay} from "../Elements/elements";
import Loader from "react-loader-spinner";

const LoadingWindow: React.FC = () => {


    return (
        <>
            <Overlay  opacity={0.85} zIndex={134}  />
            <div className="lw_window">

                <div className="lw_message_window">
                    <div className="lw_close_div">

                    </div>
                    <div className="lw_message_text">
                        <div className="lw_loader-container"><Loader  type="Puff" color="cornflowerblue" height={70} width={70} /></div>
                        <div className="lw_text-container">Пожалуйста, подождите идет загрузка данных</div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default LoadingWindow;
