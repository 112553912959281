import React, {useEffect, useRef, useState} from "react";

import "./UpdateDataWindow.css"
import {Overlay} from "../Elements/elements";
import Select from "react-dropdown-select";

import {useDispatch} from "react-redux";
import {
    hideUpdateDataWindow, setDriverData,
    setDriverID,
    setSystemMessage,
    setUserInfo,
    toggleLoadingWindow
} from "../../redux/reducers/system/actions";
import {CarTypeType, MessageType, SystemState} from "../../redux/reducers/system/@types";
import {AddressSuggestions} from "react-dadata";
import {daDataToken, imagePath} from "../../deployment";
import {addressAPI, driverAPI} from "../../api/api";
import {EnumStatusResponse} from "../../api/@types";
import {
    checkAndTakeOrder,
    loadOrdersFromServer,
    waitWhileWindowMessageIsOpen
} from "../../redux/reducers/system/reducers";
import {setCookie} from "../../redux/cookies";

export const editingChars = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Home', 'End'];




interface IProps {
    system: SystemState,
    onSave?: Function
    onClose?: Function
}

const UpdateDataWindow: React.FC<IProps> = ({system,onSave,onClose}) => {
    const dispatch = useDispatch();

    const garageAddress = useRef(null);

    const [carType, setCarType] = useState<string>('');
    const [bodyOption, setLocalBodyOption] = useState<string>('');
    const [localAddress, setLocalAddress] = useState<{address:any, latitude:string, longitude:string } | null>(null);
    const [length, setLocalLength] = useState<any>(null);
    const [width,  setLocalWidth] = useState<any>(null);
    const [height, setLocalHeight] = useState<any>(null);
    const [fio, setFIO] = useState<any>('');
    const [phone, setPhone] = useState<any>('+7');
    const [error, setError] = useState<boolean>(false);
    const [wasOrder, setWasOrder] = useState<boolean>(false);

    const  carTypes : CarTypeType[] = system.categories;
    const  bodyOptions : any = system.bodies;

    let  closeWindow = () => {
        if (onClose) onClose();
        dispatch(hideUpdateDataWindow());
    };
    const setBodyOption = (id:string) => {
        setLocalBodyOption(id);
    };

    const saveData = () => {
        if (!length || !width || !height || !localAddress || !bodyOption || !carType || (!system.driver_id && (phone.length!=12 || fio.length<3 ))){
            setError( true);
            return
        }
        setError( false);
        dispatch(toggleLoadingWindow(true));


        driverAPI.updateData({car_type_id: carType, body_option_id: bodyOption,
            adress: localAddress ? localAddress.address.value : '',
            latitude: localAddress ? +localAddress.latitude : 0,
            longitude: localAddress ? +localAddress.longitude : 0,
            driver_id : system.driver_id ? system.driver_id : '',
            name: system.driver_id ? null : fio,
            phone: system.driver_id ? null :  phone,
            width, length, height
        }).then( (res) => {

            if (!res.data || res.status!=200 || !res.data.status) {
                dispatch(hideUpdateDataWindow());
                dispatch(setSystemMessage("Ошибка обновления данных, попробуйте позднее !", MessageType.Error));
                dispatch(toggleLoadingWindow(false));
                return;
            }

            if ( res.data.status != EnumStatusResponse.success) {
                dispatch(hideUpdateDataWindow());
                dispatch(setSystemMessage("Ошибка: "+(res.data.error_message ? res.data.error_message : ' обработки данных ' ), MessageType.Error));
                dispatch(toggleLoadingWindow(false));
                return;
            } else {
                const  closeThisWindow = () =>{
                    dispatch(toggleLoadingWindow(false));
                    dispatch(hideUpdateDataWindow());
                }

                if (localAddress)
                    dispatch(setUserInfo({adress:  localAddress.address.value, latitude: +localAddress.latitude, longitude:+localAddress.longitude },
                                       {length, width, height}));
                if (!system.driver_id && res.data.driver_id){
                    dispatch(setDriverData(res.data.driver_id));
                    // window.location.href="/autosearch?id=" + res.data.driver_id ;
                    if (onSave) dispatch(setSystemMessage("Данные успешно обновлены !", MessageType.Info));
                    // setTimeout(()=> window.location.href="/autosearch?id=" + res.data.driver_id, 5000);
                }
                if (res.data.driver_id) {
                    setCookie('driver_id', res.data.driver_id, 365);
                }
                console.log('driver_id', res.data.driver_id);
                if (onSave) { onSave();  closeThisWindow(); return; }

                // dispatch(setSystemMessage("Данные успешно обновлены !", MessageType.Info));
                if (!system.was_order_id) { closeThisWindow(); return;}

                // waitWhileWindowMessageIsOpen(MessageType.None).then(() => {


                loadOrdersFromServer( res.data.driver_id , dispatch).then(()=>{

                    setTimeout(()=>  setWasOrder(true), 500);

                }).catch(()=>  closeThisWindow());



            }
        }).catch((e)=>{
            dispatch(toggleLoadingWindow(false));
            dispatch(setSystemMessage("Ошибка обработки ответа сервера " + e.message, MessageType.Error));
        })

    };

    useEffect(()=>{
        dispatch(hideUpdateDataWindow());
        dispatch(toggleLoadingWindow(false));
        checkAndTakeOrder(system.was_order_id, system, dispatch);
    }, [wasOrder])

    const setGarageEntered = (garage_entered: boolean, address?: any, latitude?: string,
                              longitude?: string) => {
        console.log(address ,longitude , latitude);
        if (address && longitude && latitude)
            setLocalAddress({address, longitude, latitude});
        else
            setLocalAddress(null);
    };

    useEffect( () => {
        if (system.data) {
            if (system.data.car_type_id) setCarType(system.data.car_type_id);
            if (system.data.body_option_id)  setBodyOption(system.data.body_option_id)  ;
            if (system.data.body_size && system.data.body_size.length)  setLocalLength(''+system.data.body_size.length) ;
            if (system.data.body_size && system.data.body_size.width)  setLocalWidth(''+system.data.body_size.width) ;
            if (system.data.body_size && system.data.body_size.height)  setLocalHeight(''+system.data.body_size.height);
            if (system.data.garage && system.data.garage.adress)
                setLocalAddress({ address:{value:system.data.garage.adress},
                                        latitude: ''+system.data.garage.latitude,
                                        longitude: ''+system.data.garage.longitude }) ;
        }
    }, []);

    console.log('localAddress', localAddress && localAddress.address && localAddress.address.value ? localAddress.address.value : 'xz');
    return (
        <>
            <Overlay onClick={closeWindow} zIndex={44} />
            <div className="updateDataWindow">
                <div className="page_back_link" onClick={closeWindow}>
                    <img src={imagePath+"/image/close.svg"} alt="закрыть"  className="close" onClick={closeWindow}/>
                </div>
                <div >
                    <div className='orderNumberTitle'>Уточните характеристики вашего ТС  </div>
                </div>
                {!system.driver_id &&
                    <div>
                        <div className="vehicle_container_item vehicle_container_item-short">
                            <label htmlFor="vehicle_car_type" className="vehicle_input_label">Введите персональные
                                данные</label>
                            <div className={"vehicle_input vehicle_input_tiny " + (fio.length>=3 ?' vehicle_input_complete' : (error ? ' error' : ''))}
                                 style={{"margin": "unset"}}>
                                <input className="vehicle_input_text"
                                       placeholder="Фамилия Имя Отчество"
                                       type="text"
                                       value={fio}
                                       onKeyPress={(e) => {
                                           // if (!(/^[а-яА-Я]*\s[а-яА-Я]*\s[а-яА-Я\s]*$/.test(target.value)))
                                           if (/[а-яА-Я\s]/.test(e.key))
                                               return;
                                           e.preventDefault();
                                       }}
                                       onChange={(e) => setFIO(e.target.value)}
                                />
                            </div>

                            <div className={"vehicle_input vehicle_input_tiny " + (phone.length==12 ?' vehicle_input_complete' : (error ? ' error' : ''))}
                                 style={{"margin": "unset", "marginTop": '10px'}}>
                                <input className={"vehicle_input_text " }
                                       placeholder="Номер телефона"
                                       type="text"
                                       value={phone}

                                       onKeyPress={(e) => {
                                           let target = e.target as HTMLInputElement;
                                           // if (!(/^[а-яА-Я]*\s[а-яА-Я]*\s[а-яА-Я\s]*$/.test(target.value)))
                                           if (((/[0-9-]/.test(e.key)) && target.value.length < 12) || (e.key == '+' && target.value == ""))
                                               return;
                                           e.preventDefault();
                                       }}
                                       onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>


                        </div>
                    </div>
                }
                <div  >

                    <div className ="vehicle_container_item vehicle_container_item-short "  >
                        <label htmlFor="vehicle_car_type" className="vehicle_input_label">Грузоподъемность</label>
                        <Select
                            //@ts-ignore
                            options={carTypes || []}
                            values={carType !== ''   ?
                                [carType] : []}
                            color="transparent"
                            className={"vehicle_select_color " + (carType ?' vehicle_input_complete' : (error ? ' error' : ''))}
                            searchable={false}
                            labelField="weight_to"
                            onChange={() => {}}
                            contentRenderer={() => (
                                <>
                                    {carType === '' ?
                                        <div className="vehicle_select_placeholder"
                                              >
                                            {carTypes ? 'Выберите категорию вашего ТС' : ''}
                                        </div> :
                                        <div className="vehicle_select_text">
                                            {'До ' + carTypes.find((car_type: any) => (
                                                car_type.id === carType))?.weight_to  + ' кг'

                                            }
                                        </div>
                                    }
                                </>
                            )}
                            dropdownRenderer={({props, methods}) => (
                                <>
                                    {props.options.length === 0 ?
                                                <div className="vehicle_select_nodata">Нет данных</div> :
                                                <>
                                                    {props.options.map((item: any, index) => (
                                                        <span key={'car_type_' + index} tabIndex={1}
                                                              className="react-dropdown-select-item vehicle_select_item"
                                                              onClick={() => {
                                                                  methods.addItem(item);
                                                                  setCarType(item.id);
                                                                  if (carType !== item.id)
                                                                      setBodyOption('');
                                                              }}>{'До ' + item.weight_to + ' кг'}
														</span>
                                                    ))}
                                                </>
                                    }
                                </>

                            )}
                        />
                    </div>

                    <div className= "vehicle_container_item vehicle_container_item-short">
                        <label htmlFor="vehicle_car_type" className="vehicle_input_label">Тип кузова</label>
                        <Select
                            disabled={!carType}
                            options={bodyOptions || []}
                            values={bodyOption !== '' ? [bodyOption] : []}
                            color="transparent"
                            className={"vehicle_select_color " + (bodyOption ?' vehicle_input_complete' : (error ? ' error' : ''))}
                            searchable={false}
                            labelField="body_option"
                            onChange={() => {}}
                            contentRenderer={() => (
                                <>
                                    {bodyOption === '' ?
                                        <div className="vehicle_select_placeholder"
                                             >
                                            {bodyOptions ? 'Выберите тип кузова вашего ТС' : ''}
                                        </div> :
                                        <div className="vehicle_select_text">
                                            {  bodyOptions.find((body: any) => body.id === bodyOption)?.name }
                                        </div>
                                    }
                                </>
                            )}
                            dropdownRenderer={({props, methods}) => (
                                <>
                                    {props.options.length === 0 ?
                                        <div className="vehicle_select_nodata">Нет данных</div> :
                                        <>
                                            {props.options.map((item: any, index) => (
                                                <span key={'body_type_' + index} tabIndex={1}
                                                      className="react-dropdown-select-item vehicle_select_item"
                                                      onClick={() => {
                                                          methods.addItem(item);
                                                          setBodyOption(item.id);
                                                      }}>
                                                    {item.name}
												</span>
                                            ))}
                                        </>
                                    }
                                </>

                            )}
                        />
                    </div>


                    <div className="vehicle_container_item vehicle_container_item-short">
                        <label htmlFor="vehicle_car_type" className="vehicle_input_label">Адрес гаража (места жительства)</label>
                        <div id="vehicle_address"
                             className={'vehicle_input' + (localAddress ?' vehicle_input_complete' : (error ? ' error' : ''))}>

                            <AddressSuggestions
                                token={daDataToken}
                                ref={garageAddress}
                                defaultQuery={ localAddress && localAddress.address && localAddress.address.value ? localAddress.address.value : '' }
                                // value={ localAddress && localAddress.address ? localAddress.address : null}
                                count={5}
                                containerClassName={"add-form__address input-wrap_address"}
                                onChange={(suggestion) => {
                                    if (suggestion && suggestion.value) {
                                        setGarageEntered(true, suggestion, suggestion.data?.geo_lat || '',
                                            suggestion.data?.geo_lon || '');
                                    }
                                }}
                                inputProps={{className: 'vehicle_input_text', placeholder: 'Введите адрес гаража', type: 'text',
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                        setGarageEntered(false, null, '', '');
                                    }
                                }}
                                suggestionClassName="suggestionClassName"
                                suggestionsClassName="suggestionsClassName"
                            />
                        </div>
                        {navigator.geolocation &&
                        <div className="vehicle_geo" onClick={() => {
                            navigator.geolocation.getCurrentPosition(async (pos: any) => {
                                let response = await addressAPI.getAddressByCoords(pos.coords.latitude,
                                    pos.coords.longitude);
                                if (response && response.data && response.data.suggestions.length > 0) {
                                    setGarageEntered(true, response.data.suggestions[0],
                                        response.data.suggestions[0].data.geo_lat,
                                        response.data.suggestions[0].data.geo_lon);
                                }
                            });
                        }}>
                            Моё местоположение
                        </div>
                        }
                    </div>
                </div>
                <label htmlFor="vehicle_car_type" className="vehicle_input_label" style={{"marginTop":'15px'}}>Габаритные характеристики ТС</label>
                <div className="vehicle_input_container vehicle_params">
                    <div className="vehicle_block_input">
                        <label>&nbsp;Длина кузова</label>
                        <div id="vehicle_length" className={'vehicle_input vehicle_input_tiny' +
                        (length ? ' vehicle_input_complete' : (error ? ' error' : ''))}>
                            <input className="vehicle_input_text" placeholder="в метрах" type="text"
                                   value={length ? length.replace('.', ',') : ''} onKeyPress={(e) => {
                                let target = e.target as HTMLInputElement;
                                if ((target.value.length === 5 && !editingChars.includes(e.key)) ||
                                    !(/[0-9.,]/.test(e.key))) {
                                    e.preventDefault();
                                    return;
                                }
                                if (['.', ','].includes(e.key)) {
                                    e.preventDefault();
                                    if (length.indexOf(',') === -1)
                                        document.execCommand('insertText', false, ',');
                                }
                            }}
                                   onChange={(e) => {
                                       if (parseInt(e.target.value) > 0) {
                                           setLocalLength( e.target.value);
                                       } else {
                                           setLocalLength(  '');
                                       }
                                   }}
                            />
                        </div>
                        <label>&nbsp;Пример 3,2</label>
                    </div>

                    <div className="vehicle_block_input">
                        <label>&nbsp;Ширина кузова</label>
                        <div id="vehicle_width" className={'vehicle_input vehicle_input_tiny' +
                        (width ? ' vehicle_input_complete' : (error ? ' error' : ''))}>

                            <input className="vehicle_input_text" placeholder="в метрах" type="text"
                                   value={width ? width.replace('.', ',') : ''} onKeyPress={(e) => {
                                let target = e.target as HTMLInputElement;
                                if ((target.value.length === 5 && !editingChars.includes(e.key)) ||
                                    !(/[0-9.,]/.test(e.key))) {
                                    e.preventDefault();
                                    return;
                                }
                                if (['.', ','].includes(e.key)) {
                                    e.preventDefault();
                                    if (width.indexOf(',') === -1)
                                        document.execCommand('insertText', false, ',');
                                }
                            }}
                                   onChange={(e) => {
                                       if (parseInt(e.target.value) > 0) {
                                           setLocalWidth( e.target.value);
                                       } else {
                                           setLocalWidth( '');
                                       }
                                   }}
                            />
                        </div>
                        <label>&nbsp;Пример 1,9</label>
                    </div>
                    <div className="vehicle_block_input">
                        <label>&nbsp;Высота кузова</label>
                        <div id="vehicle_height" className={'vehicle_input vehicle_input_tiny' +
                        (height ? ' vehicle_input_complete' : (error ? ' error' : ''))}>

                            <input className="vehicle_input_text" placeholder="в метрах" type="text"
                                   value={height ? height.replace('.', ',') : ''} onKeyPress={(e) => {
                                let target = e.target as HTMLInputElement;
                                if ((target.value.length === 5 && !editingChars.includes(e.key)) ||
                                    !(/[0-9.,]/.test(e.key))) {
                                    e.preventDefault();
                                    return;
                                }
                                if (['.', ','].includes(e.key)) {
                                    e.preventDefault();
                                    if (height.indexOf(',') === -1)
                                        document.execCommand('insertText', false, ',');
                                }
                            }}
                                   onChange={(e) => {
                                       if (parseInt(e.target.value) > 0) {
                                           setLocalHeight( e.target.value);
                                       } else {
                                           setLocalHeight( '');
                                       }
                                   }}
                            />
                        </div>
                        <label>&nbsp;Пример 1,6</label>
                    </div>
                </div>

                <div className='orderNumber middleLine buttonMedia buttonMediaUpdate'>
                    <div className={ "buttonLink " + (!length || !width || !height || !localAddress || !bodyOption || !carType ||
                        (!system.driver_id && (phone.length!=12 || fio.length < 3 ))? 'disabled' : '' )}
                         onClick={() => saveData()} >Сохранить</div>
                </div>
            </div>
        </>
    )
};

export default UpdateDataWindow;
