import React, { Component } from "react";

import "./Message.css"
import {MessageType} from "../../redux/reducers/system/@types";
import {Overlay} from "../Elements/elements";




interface IProps {
    text: string ,
    type: MessageType,
    onClose?: Function
}

const Message: React.FC<IProps> = ({text, type, onClose}) => {


    let  closeWindow = () => {
        if (onClose) onClose();
    };

    return (
        <>
            <Overlay onClick={closeWindow} />
            <div className="window">

                <div className="message_window">
                    <div className="close_div">
                        {onClose && <div className='close_button' onClick={closeWindow} >х</div> }
                    </div>
                    <div className={ 'message_type_' + type }>
                       <div>{text}</div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Message;
