import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import "./MainScreen.css"
import OrderInfo from "../OrderInfo/OrderInfo";
import {OrderStruct} from "../../redux/reducers/orders/@types";
import {EnumDecline, MessageType, SystemState} from "../../redux/reducers/system/@types";
import {Overlay} from "../Elements/elements";
import Message from "../Message/Message";
import OrderCard from "../Elements/OrderCard/OrderCard";
import {setDriverData, setDriverID, setSystemMessage, toggleLoadingWindow} from "../../redux/reducers/system/actions";
import {driverAPI} from "../../api/api";
import {EnumStatusResponse} from "../../api/@types";
import {
    checkAndTakeOrder,
    checkOrder,
    getCategory,
    loadOrdersFromServer,
    waitWhileWindowMessageIsOpen
} from "../../redux/reducers/system/reducers";
import UpdateDataWindow from "../UpdateDataWindow/UpdateDataWindow";
import moment from "moment";

let isUpdateData : {show:boolean, status: boolean} =  {show:false, status:false};
const MainScreen: React.FC = () => {
    const allDay = "все";
    const [currentOrder, setCurrentOrder] = useState<OrderStruct | null>(null);
    const [currentCategoryID, setCurrentCategoryID] = useState<string | null>(null);
    const [localIsUpdate, setLocalIsUpdate] =  useState<{show:boolean, status: boolean}>({show:false, status:false});
    const [selectedDay, setSelectedDay] =  useState<string>(allDay);



    const state = useSelector((state: RootState) => ({
        system: state.systemReducer,
    }));
    const system: SystemState = state.system;

    const dispatch = useDispatch();

    let showWindowOrderInfo = (x: OrderStruct) => {
        setCurrentOrder(x);
    };

    const checkDriverData = async () =>{
        if (!system.data) return  new Promise(resolve => resolve(false));
        if (system.data.body_option_id =="" || system.data.car_type_id == "" || system.data.garage.adress == "" ){

            isUpdateData = {show:true,status:false};
            setLocalIsUpdate(isUpdateData);
            return new Promise(resolve => {
                let int = setInterval(()=>{
                    if (!isUpdateData.show) {
                        clearInterval(int);
                        resolve(isUpdateData.status)
                    }
                }, 300, 300)
            })
        }
        return  new Promise(resolve => resolve(true));
    };

    const notInteresting = async (reason:EnumDecline) => {

        let check = await  checkDriverData();
        if (!check) return ;
        dispatch(toggleLoadingWindow(true));
        let data = await driverAPI.notInteresting(system.driver_id, reason);
        if (data.status == 200 && data.data.status === EnumStatusResponse.success) {
            if (reason == EnumDecline.NOT_INTERESTING_IM_BUSY) {
                dispatch(setSystemMessage("Спасибо за информацию, мы постараемся найти заказы для вас в другое время.", MessageType.Info));
                dispatch(toggleLoadingWindow(true));
            }
            else {
                dispatch(toggleLoadingWindow(true));
                loadOrdersFromServer( system.driver_id, dispatch).then( () => {
                    dispatch(setSystemMessage("Перечень заказов успешно обновлен", MessageType.Info));
                    dispatch(toggleLoadingWindow(false));
                }).catch((e)=> {
                    dispatch(toggleLoadingWindow(false));
                })
            }
        }
        else
            dispatch(setSystemMessage("Произошла ошибка в процессе отправки сообщения, попробуйте позже", MessageType.Error))
    };


    const getBodyOption = (id : string) =>{
        let body = system.bodies.find(x => x.id == id);
        return body ? body.name : 'ЛЮБОЙ ЗАКРЫТЫЙ';
    };

    const setCategory = (id:string) => {
        setCurrentCategoryID(id)
    };

    let orders = system.data && system.data.orders ? system.data.orders : [];
    let categories: { id: string, maxWeight: number, image: string }[] = [];
    orders.forEach(x => {
        let category = getCategory(x.car_type_id);
        if (!categories.some(o => o.maxWeight == category.maxWeight))
            categories.push(category)
    });

    let dateList : string[] = [allDay];
    orders.forEach(x=> {
                        if (!dateList.some( z=> z == moment(x.date).format("DD.MM.YY")) )
                            dateList.push( moment(x.date).format("DD.MM.YY"))
                        });

    categories.sort((x,y)=> x.maxWeight - y.maxWeight);
    console.log('currentCategoryID: ', currentCategoryID);
    console.log('categories', categories);
    let filtered_orders = orders.filter(x=> (!currentCategoryID || x.car_type_id == currentCategoryID) &&
            (selectedDay == allDay || moment(x.date).format("DD.MM.YY") == selectedDay));
    return (
        <>
            {isUpdateData && isUpdateData.show &&
                                <UpdateDataWindow system={system} onSave={()=> { isUpdateData = {show:false, status:true}; console.log('UpdateDataWindow onSave'); }}
                                                              onClose={()=>isUpdateData = {show:false, status:false}}/>}
            {system.update_window_visible && <UpdateDataWindow system={system}  />}

            {categories.length == 0 && !system.is_loading && <Message text={"Извините, но для вас заказы отсутсвуют !"} type={MessageType.Warning} />}
            {categories.length > 0 && system.message.type != MessageType.None &&
                <Message text={system.message.text} type={system.message.type} onClose={()=> dispatch(setSystemMessage("", MessageType.None))}/>
            }
            {categories.length > 0 &&
                <div className="mainContainer">

                    <div className="dateTime"><h1>Добрый день {system.data ? system.data.name : ''}</h1></div>
                    <div className="categoryList">
                         {
                            categories.map(x =>
                            <div className={'categoryCard ' + (x.id == currentCategoryID? 'active':'')} key={'card' + x.id} onClick={ () => setCategory(x.id)}>
                                <div className='categoryCardImage'><img src={x.image}/></div>
                                <div className='categoryWord'>Грузоподъемность</div>
                                <div className='categoryWeight'>до {x.maxWeight} кг.</div>
                            </div>)
                        }
                    </div>
                    <div className="dateList">
                        <div>Дата подачи:</div>
                        {dateList && dateList.map((x,index)=>
                            <div key={'date_list_'+index} className={"dateListItem " + (x==selectedDay ? 'dateListItemActive' : '')}
                                 onClick={()=>setSelectedDay(x)}>{x}</div>)}
                    </div>
                    <div className="orderList">
                        {!orders.filter(x=> !currentCategoryID || x.car_type_id == currentCategoryID).length  &&
                        <div className="noCategories">Не найдены заказы удовлетворяющие выбранным критериям</div>}
                        {filtered_orders.length > 0 ? filtered_orders.map((x,index) =>
                                                <OrderCard  key={'order_card_' + x.id + '_' + index} order={x}
                                                           system={system}
                                                           onClick={(order:OrderStruct) => showWindowOrderInfo(order)} />) : ''
                        }
                        <div className="bottomButton">
                            <div className="gray-gradient" onClick={()=>notInteresting(EnumDecline.NOT_INTERESTING_BY_TIME)}>Заказы не подходят по времени</div>
                            <div className="gray-gradient" onClick={()=>notInteresting(EnumDecline.NOT_INTERESTING_BY_ADDRESS)}>Заказы не подходят по адресу подачи</div>
                            <div className="no-need" onClick={()=>notInteresting(EnumDecline.NOT_INTERESTING_IM_BUSY)}>На завтра занят</div>

                        </div>
                    </div>
                    {
                        currentOrder &&
                        <Overlay onClick={() => setCurrentOrder(null)}/> &&
                        <OrderInfo
                            body_option={getBodyOption(currentOrder?.body_option_id)}
                            system={system}
                            category_id={currentOrder?.car_type_id}
                            order={currentOrder}
                            onClose={() => setCurrentOrder(null)}
                        />
                    }
                </div>
            }
        </>
    )
};

export default MainScreen;
